<template>
  <el-row class="displays">
    <el-col :span="24">
      <div class="pages hidden-xs-only">
        <div class="banner">
          <img src="@/assets/images/accunt/banner.png" alt="" />
          <div class="title" :class="{ weibei: lang }">
            {{ $t("Dhsynopsis").title }}
          </div>
        </div>
        <div class="textConter">
          <div class="conter1">
            <div class="left">
              <div class="title" :class="{ weibei: lang }">
                {{ $t("Dhsynopsis").h1 }}
              </div>
              <p>
                {{ $t("Dhsynopsis").p1 }}
              </p>
              <p>
                {{ $t("Dhsynopsis").p2 }}
              </p>
              <p :style="lang ? 'white-space: nowrap;' : ''">
                {{ $t("Dhsynopsis").p3 }}
              </p>
            </div>
            <div class="right">
              <img src="@/assets/images/accunt/ac1.png" alt="" />
            </div>
          </div>
          <div class="conter2">
            <!-- <div class="title" :class="{weibei:lang}">{{ $t("Dhsynopsis").h2 }}</div> -->
            <div class="title_1">{{ $t("Dhsynopsis").h3 }}</div>
            <p>
              {{ $t("Dhsynopsis").h4 }}
            </p>
            <p>{{ $t("Dhsynopsis").h5 }}</p>
            <p class="h1">{{ $t("Dhsynopsis").h6 }}</p>
            <p>
              {{ $t("Dhsynopsis").h7 }}
            </p>
            <p class="h1">{{ $t("Dhsynopsis").h8 }}</p>
            <p>
              {{ $t("Dhsynopsis").h9 }}
            </p>
            <p class="h1">{{ $t("Dhsynopsis").h10 }}</p>
            <p>
              {{ $t("Dhsynopsis").h11 }}
            </p>
            <p class="h1">{{ $t("Dhsynopsis").h12 }}</p>
            <p>
              {{ $t("Dhsynopsis").h13 }}
            </p>
            <div class="title_1">{{ $t("Dhsynopsis").h14 }}</div>
            <p>
              {{ $t("Dhsynopsis").h15 }}
            </p>
          </div>
          <div class="conter3" id="zhuban">
            <div class="title" :class="{ weibei: lang }">
              {{ $t("Dhsynopsis").zhuban }}
            </div>
            <div class="list">
              <div class="item">
                <div class="left">
                  <img src="@/assets/images/accunt/inbar.png" alt="" />
                  <!-- <div class="name">{{ $t("Dhsynopsis").z1 }}</div> -->
                </div>
                <div class="right">
                  <div class="top">
                    <div class="name">{{ $t("Dhsynopsis").z1 }}</div>
                    <div class="p">
                      {{ $t("Dhsynopsis").z2 }}
                    </div>
                  </div>
                  <div class="bottom">
                    {{ $t("Dhsynopsis").z3 }}
                    <a
                      :href="'http://' + $t('Dhsynopsis').z4"
                      target="_blank"
                      >{{ $t("Dhsynopsis").z4 }}</a
                    >.
                  </div>
                </div>
              </div>
              <div class="item">
                <div class="left">
                  <div class="name">{{ $t("Dhsynopsis").z5 }}</div>
                </div>
                <div class="right">
                  <div class="top">
                    <div class="name">{{ $t("Dhsynopsis").z5 }}</div>
                    <div class="p">
                      {{ $t("Dhsynopsis").z6 }}
                    </div>
                  </div>
                  <div class="bottom">
                    {{ $t("Dhsynopsis").z7 }}
                    <a
                      :href="'https://' + $t('Dhsynopsis').z8"
                      target="_blank"
                      >{{ $t("Dhsynopsis").z8 }}</a
                    >.
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="conter3" id="cenban">
            <div class="title" :class="{ weibei: lang }">
              {{ $t("Dhsynopsis").cenban }}
            </div>
            <div class="list">
              <div class="item">
                <div class="left">
                  <img src="@/assets/images/accunt/inbar.png" alt="" />
                  <!-- <div class="name">{{ $t("Dhsynopsis").c1 }}</div> -->
                </div>
                <div class="right">
                  <div class="top">
                    <div class="name">{{ $t("Dhsynopsis").z1 }}</div>
                    <div class="p">
                      {{ $t("Dhsynopsis").z2 }}
                    </div>
                  </div>
                  <div class="bottom">
                    {{ $t("Dhsynopsis").z3 }}
                    <a
                      :href="'http://' + $t('Dhsynopsis').z4"
                      target="_blank"
                      >{{ $t("Dhsynopsis").z4 }}</a
                    >.
                  </div>
                </div>
              </div>
              <div class="item">
                <div class="left">
                  <img src="../../assets/images/accunt/icbr.png" alt="" />
                  <!-- <div class="name">{{ $t("Dhsynopsis").c2 }}</div>
                  <div class="name">{{ $t("Dhsynopsis").c3 }}</div> -->
                </div>
                <div class="right">
                  <div class="top">
                    <div class="name">
                      {{ $t("Dhsynopsis").c2 }}{{ $t("Dhsynopsis").c3 }}
                    </div>
                    <div class="p">
                      {{ $t("Dhsynopsis").c4 }}
                    </div>
                  </div>
                  <div class="bottom">
                    {{ $t("Dhsynopsis").c5 }}
                    <a
                      :href="'http://' + $t('Dhsynopsis').c6"
                      target="_blank"
                      >{{ $t("Dhsynopsis").c6 }}</a
                    >.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="pages1 hidden-sm-and-up">
        <div class="banner">
          <img src="@/assets/images/accunt/banner1.png" alt="" />
          <div class="title" :class="{ weibei: lang }">
            {{ $t("Dhsynopsis").title }}
          </div>
        </div>
        <div class="textConter">
          <div class="conter1">
            <div class="left">
              <div class="title" :class="{ weibei: lang }">
                {{ $t("Dhsynopsis").h1 }}
              </div>
              <p>
                {{ $t("Dhsynopsis").p1 }}
              </p>
              <p>
                {{ $t("Dhsynopsis").p2 }}
              </p>
              <p>
                {{ $t("Dhsynopsis").p3 }}
              </p>
            </div>
            <div class="right">
              <img src="@/assets/images/accunt/ac1.png" alt="" />
            </div>
          </div>
          <div class="conter2">
            <!-- <div class="title" :class="{weibei:lang}">{{ $t("Dhsynopsis").h2 }}</div> -->
            <div class="title_1">{{ $t("Dhsynopsis").h3 }}</div>
            <p>
              {{ $t("Dhsynopsis").h4 }}
            </p>
            <p>{{ $t("Dhsynopsis").h5 }}</p>
            <p class="h1">{{ $t("Dhsynopsis").h6 }}</p>
            <p>
              {{ $t("Dhsynopsis").h7 }}
            </p>
            <p class="h1">{{ $t("Dhsynopsis").h8 }}</p>
            <p>
              {{ $t("Dhsynopsis").h9 }}
            </p>
            <p class="h1">{{ $t("Dhsynopsis").h10 }}</p>
            <p>
              {{ $t("Dhsynopsis").h11 }}
            </p>
            <p class="h1">{{ $t("Dhsynopsis").h12 }}</p>
            <p>
              {{ $t("Dhsynopsis").h13 }}
            </p>
            <div class="title_1">{{ $t("Dhsynopsis").h14 }}</div>
            <p>
              {{ $t("Dhsynopsis").h15 }}
            </p>
          </div>
          <div class="conter3" id="zhuban1">
            <div class="title" :class="{ weibei: lang }">
              {{ $t("Dhsynopsis").zhuban }}
            </div>
            <div class="list">
              <div class="item">
                <div class="left">
                  <img src="@/assets/images/accunt/inbar.png" alt="" />
                  <!-- <div class="name">{{ $t("Dhsynopsis").z1 }}</div> -->
                </div>
                <div class="right">
                  <div class="top">
                    <div class="name">{{ $t("Dhsynopsis").z1 }}</div>
                    <div class="p">
                      {{ $t("Dhsynopsis").z2 }}
                    </div>
                  </div>
                  <div class="bottom">
                    {{ $t("Dhsynopsis").z3 }}
                    <a
                      :href="'http://' + $t('Dhsynopsis').z4"
                      target="_blank"
                      >{{ $t("Dhsynopsis").z4 }}</a
                    >.
                  </div>
                </div>
              </div>
              <div class="item">
                <div class="left">
                  <div class="name">{{ $t("Dhsynopsis").z5 }}</div>
                </div>
                <div class="right">
                  <div class="top">
                    <div class="name">{{ $t("Dhsynopsis").z5 }}</div>
                    <div class="p">
                      {{ $t("Dhsynopsis").z6 }}
                    </div>
                  </div>
                  <div class="bottom">
                    {{ $t("Dhsynopsis").z7 }}
                    <a
                      :href="'https://' + $t('Dhsynopsis').z8"
                      target="_blank"
                      >{{ $t("Dhsynopsis").z8 }}</a
                    >.
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="conter3" id="cenban1">
            <div class="title" :class="{ weibei: lang }">
              {{ $t("Dhsynopsis").cenban }}
            </div>
            <div class="list">
              <div class="item">
                <div class="left">
                  <img src="@/assets/images/accunt/inbar.png" alt="" />
                  <!-- <div class="name">{{ $t("Dhsynopsis").c1 }}</div> -->
                </div>
                <div class="right">
<div class="top">
                    <div class="name">{{ $t("Dhsynopsis").z1 }}</div>
                    <div class="p">
                      {{ $t("Dhsynopsis").z2 }}
                    </div>
                  </div>
                  <div class="bottom">
                    {{ $t("Dhsynopsis").z3 }}
                    <a
                      :href="'http://' + $t('Dhsynopsis').z4"
                      target="_blank"
                      >{{ $t("Dhsynopsis").z4 }}</a
                    >.
                  </div>
                </div>
              </div>
              <div class="item">
                <div class="left">
                  <img src="../../assets/images/accunt/icbr.png" alt="" />
                  <!-- <div class="name">{{ $t("Dhsynopsis").c2 }}</div>
                  <div class="name">{{ $t("Dhsynopsis").c3 }}</div> -->
                </div>
                <div class="right">
                  <div class="top">
                    <div class="name">
                      {{ $t("Dhsynopsis").c2 }}{{ $t("Dhsynopsis").c3 }}
                    </div>
                    <div class="p">
                      {{ $t("Dhsynopsis").c4 }}
                    </div>
                  </div>
                  <div class="bottom">
                    {{ $t("Dhsynopsis").c5 }}
                    <a
                      :href="'http://' + $t('Dhsynopsis').c6"
                      target="_blank"
                      >{{ $t("Dhsynopsis").c6 }}</a
                    >.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </el-col>
  </el-row>
</template>
<script>
export default {
  components: {},
  data() {
    return {};
  },
  watch: {
    $route(to, from) {
      if (to.path == "/account") {
        if (this._isMobile()) {
          console.log(123);
          if (this.$route.params.type == 0) {
            let section = document.getElementById("zhuban1");
            if (section) {
              this.$nextTick(() => {
                section.scrollIntoView();
              });
            }
          }
          if (this.$route.params.type == 1) {
            let section = document.getElementById("cenban1");
            if (section) {
              this.$nextTick(() => {
                section.scrollIntoView();
              });
            }
          }
        } else {
          if (this.$route.params.type == 0) {
            let section = document.getElementById("zhuban");
            if (section) {
              console.log(section);
              this.$nextTick(() => {
                section.scrollIntoView();
              });
            }
          }
          if (this.$route.params.type == 1) {
            console.log(45123);
            let section = document.getElementById("cenban");
            if (section) {
              this.$nextTick(() => {
                section.scrollIntoView();
              });
            }
          }
        }
      }
    },
  },
  mounted() {},
  computed: {
    lang() {
      const localeLang = window.localStorage.getItem("locale") || "zh-CN";
      return localeLang === "zh-CN";
    },
  },
  methods: {
    _isMobile() {
      let flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      return flag;
    },
  },
};
</script>
<style lang="stylus" scoped>
.displays {
  display: flex;
  align-items: center;
  justify-content: center;
}

.Pageconter {
  margin: auto;
}

.pages {
  width: 100%;

  .banner {
    width: 100%;
    background: #405E3C;
    position: relative;

    .title {
      position: absolute;
      top: 50%;
      left: 50%;
      margin-left: -130px;
      margin-top: -40px;
      width: 260px;
      height: 80px;
      background: rgba(141, 198, 63, 0.9);
      text-align: center;
      line-height: 80px;
      font-weight: 700;
      font-size: 42px;
      color: #FFFFFF;
    }

    img {
      width: 100%;
      height: 100%;
      display: block;
      object-fit: cover;
    }
  }

  .textConter {
    max-width: 1520px;
    margin:69px auto 97px auto;
    padding: 0 60px;
    box-sizing: border-box;

    .conter1 {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;

      .right {
        width: 50%;
        margin-left: 29px;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .left {
        flex: 1;

        .title {
          text-align: left;
          font-weight: 700;
          font-size: 28px;
          color: #405E3C;
          margin-bottom: 48px;
        }

        p {
          font-size: 16px;
          line-height: 24px;
          color: #333333;
          text-align: left;
          margin-bottom: 27px;
        }
      }
    }

    .conter2 {
      margin-top: 88px;

      .title {
        font-weight: 700;
        font-size: 28px;
        color: #405E3C;
        text-align: left;
        margin-bottom: 42px;
      }

      .title_1 {
        font-weight: 700;
        font-size: 20px;
        color: #8DC63F;
        margin-bottom: 23px;
        text-align: left;
      }

      p {
        text-align: left;
        font-size: 16px;
        line-height: 24px;
        color: #333333;
        margin-bottom: 30px;
      }

      .h1 {
        color: #405E3C;
        font-weight: 700;
      }
    }

    .conter3 {
      margin-top: 85px;
      padding-top: 260px !important;
      margin-top: -185px !important;

      .title {
        font-weight: 700;
        font-size: 28px;
        color: #405E3C;
        text-align: left;
      }

      .list {
        margin-top: 54px;

        .item {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          margin-bottom: 30px;

          .left {
            width: 270px;
            height: 226px;
            background: #F5F5F5;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-wrap: wrap;
            align-content: center;

            img {
              width: 166px;
              margin-bottom: 32px;
            }

            .name {
              width: 100%;
              font-weight: 700;
              font-size: 16px;
              color: #405E3C;
            }
          }

          .right {
            flex: 1;
            height: 226px;
            margin-left: 33px;
            display: flex;
            flex-wrap: wrap;
            align-content: space-between;
            padding: 10px 0;
            box-sizing: border-box;
            text-align: left;

            .top {
              width: 100%;

              .name {
                font-weight: 700;
                font-size: 16px;
                color: #405E3C;
                margin-bottom: 15px;
              }

              .p {
                font-size: 16px;
                line-height: 24px;
                color: #333333;
              }
            }

            .bottom {
              font-size: 16px;
              color: #8DC63F;

              a {
                color: #1890FF;
                font-weight: bold;
              }
            }
          }
        }
      }
    }
  }
}

.pages1 {
  width: 100%;

  .banner {
    width: 100%;
    height: 190px;
    background: #405E3C;
    position: relative;

    .title {
      position: absolute;
      top: 50%;
      left: 50%;
      margin-left: -60px;
      margin-top: -25px;
      width: 120px;
      height: 50px;
      background: rgba(141, 198, 63, 0.9);
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 700;
      font-size: 16px;
      color: #FFFFFF;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .textConter {
    margin: auto;
    margin-top: 40px;
    margin-bottom: 33px;
    padding: 0 15px;
    box-sizing: border-box;

    .conter1 {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      flex-wrap: wrap;

      .right {
        width: 100%;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .left {
        flex: 1;

        .title {
          text-align: left;
          font-weight: 700;
          font-size: 20px;
          color: #405E3C;
          margin-bottom: 29px;
        }

        p {
          font-size: 14px;
          line-height: 21px;
          color: #333333;
          text-align: left;
          margin-bottom: 17px;
        }
      }
    }

    .conter2 {
      margin-top: 59px;

      .title {
        font-weight: 700;
        font-size: 20px;
        color: #405E3C;
        text-align: left;
        margin-bottom: 35px;
      }

      .title_1 {
        font-weight: 700;
        font-size: 16px;
        color: #8DC63F;
        margin-bottom: 23px;
        text-align: left;
      }

      p {
        text-align: left;
        font-size: 14px;
        line-height: 21px;
        color: #333333;
        margin-bottom: 16px;
      }

      .h1 {
        color: #405E3C;
        font-weight: 700;
      }
    }

    .conter3 {
      margin-top: 30px;
      padding-top: 130px !important;
      margin-top: -100px !important;

      .title {
        font-weight: 700;
        font-size: 20px;
        color: #405E3C;
        text-align: left;
      }

      .list {
        margin-top: 30px;

        .item {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          margin-bottom: 30px;
          flex-wrap: wrap;
          align-content: flex-start;

          .left {
            width: 100%;
            height: 130px;
            background: #F5F5F5;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-wrap: wrap;
            align-content: center;

            img {
              width: 120px;
              margin-bottom: 10px;
            }

            .name {
              width: 100%;
              font-weight: 700;
              font-size: 14px;
              color: #405E3C;
            }
          }

          .right {
            flex: 1;
            margin-left: 10px;
            display: flex;
            flex-wrap: wrap;
            align-content: space-between;
            padding: 5px 0;
            box-sizing: border-box;
            text-align: left;

            .top {
              width: 100%;
              margin-top: 10px;

              .name {
                font-weight: 700;
                font-size: 16px;
                color: #405E3C;
                margin-bottom: 15px;
              }

              .p {
                font-size: 14px;
                line-height: 21px;
                color: #333333;
              }
            }

            .bottom {
              font-size: 14px;
              color: #8DC63F;
              margin-top: 15px;

              a {
                color: #1890FF;
                font-weight: bold;
              }
            }
          }
        }
      }
    }
  }
}
</style>